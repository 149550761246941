import React, { useEffect, useState, useContext } from "react";
import { database } from "../../firebaseConfig";
import { ref, onValue } from "firebase/database";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Table,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AuthContext } from "../../AuthContext";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  const { currentUser } = useContext(AuthContext);

  const firstDayOfCurrentMonth = moment().startOf("month").toDate();
  const [totalSales, setTotalSales] = useState(0);
  const [numOrders, setNumOrders] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [productData, setProductData] = useState([]);
  const [salesByDate, setSalesByDate] = useState([]);
  const [startDate, setStartDate] = useState(firstDayOfCurrentMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [todaySales, setTodaySales] = useState(0);
  const [yesterdaySales, setYesterdaySales] = useState(0);
  const [salesDirection, setSalesDirection] = useState("up");
  const [exchangeRates, setExchangeRates] = useState({});
  const [country, setCountry] = useState("");

  useEffect(() => {
    const userId = currentUser.uid;
    const salesRef = ref(database, `users/${userId}/products/sales`);
    onValue(salesRef, (snapshot) => {
      let total = 0;
      let orders = 0;
      const products = {};
      const salesDateMap = {};
      let totalVisitors = 0; // Add a variable to track total visitors

      let todayTotal = 0;
      let yesterdayTotal = 0;

      snapshot.forEach((childSnapshot) => {
        const sale = childSnapshot.val();
        const saleDate = moment(sale.date);

        // Apply date filter
        if (saleDate.isBetween(startDate, endDate, "day", "[]")) {
          total += sale.totalAmount;
          orders += 1;
          totalVisitors += sale.visitors || 0; // Assuming `sale.visitors` exists in your data structure

          if (saleDate.isSame(moment(), "day")) {
            todayTotal += sale.totalAmount;
          }
          if (saleDate.isSame(moment().subtract(1, "days"), "day")) {
            yesterdayTotal += sale.totalAmount;
          }

          sale.products.forEach((product) => {
            if (
              selectedProducts.length === 0 ||
              selectedProducts.includes(product.productId)
            ) {
              if (!products[product.productId]) {
                products[product.productId] = {
                  quantity: 0,
                  totalPrice: 0,
                };
              }
              products[product.productId].quantity += product.quantity;
              products[product.productId].totalPrice +=
                product.quantity * product.price; // Assuming product.price exists in your data structure
            }
          });

          const formattedDate = saleDate.format("YYYY-MM-DD");
          if (!salesDateMap[formattedDate]) {
            salesDateMap[formattedDate] = 0;
          }
          salesDateMap[formattedDate] += sale.totalAmount;
        }
      });

      setTotalSales(total);
      setNumOrders(orders);
      setConversionRate(totalVisitors > 0 ? (orders / totalVisitors) * 100 : 0); // Calculate conversion rate
      setTodaySales(todayTotal);
      setYesterdaySales(yesterdayTotal);
      setSalesDirection(
        todayTotal > 0 ? (todayTotal >= yesterdayTotal ? "up" : "down") : null
      ); // Check for zero sales

      const productList = Object.keys(products).map((productId) => ({
        name: productId,
        sales: products[productId].quantity,
        totalPrice: products[productId].totalPrice,
      }));
      setProductData(productList);
      setProductOptions(productList.map((product) => product.name));
      setSalesByDate(
        Object.keys(salesDateMap).map((date) => ({
          date,
          totalSales: salesDateMap[date],
        }))
      );
    });
  }, [startDate, endDate, selectedProducts]);

  const handleProductChange = (selected) => {
    setSelectedProducts(selected);
  };

  const handleDateRangeChange = (range) => {
    switch (range) {
      case "yesterday":
        setStartDate(moment().subtract(1, "days").toDate());
        setEndDate(moment().subtract(1, "days").toDate());
        break;
      case "last7days":
        setStartDate(moment().subtract(7, "days").toDate());
        setEndDate(new Date());
        break;
      case "last30days":
        setStartDate(moment().subtract(30, "days").toDate());
        setEndDate(new Date());
        break;
      case "thisMonth":
        setStartDate(moment().startOf("month").toDate());
        setEndDate(new Date());
        break;
      default:
        setStartDate(firstDayOfCurrentMonth);
        setEndDate(new Date());
    }
  };

  useEffect(() => {
    fetchGeolocationAndRates();
  }, []);

  const fetchGeolocationAndRates = async () => {
    try {
      // Step 1: Fetch geolocation data and currency information
      const ipapiResponse = await fetch("https://ipapi.co/json/");
      if (!ipapiResponse.ok) {
        throw new Error("Failed to fetch geolocation data");
      }
      const ipapiData = await ipapiResponse.json();
      const currencyCode = ipapiData.currency;
      setCountry(ipapiData.currency);
      // Step 2: Fetch exchange rates based on detected currency
      const ratesResponse = await fetch(
        `https://api.exchangerate-api.com/v4/latest/${currencyCode}`
      );
      if (!ratesResponse.ok) {
        throw new Error("Failed to fetch exchange rates");
      }
      const ratesData = await ratesResponse.json();
      setExchangeRates(ratesData.rates);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const convertPrice = (price, currency) => {
    if (exchangeRates[currency]) {
      return (price * exchangeRates[currency]).toFixed(2);
    } else {
      return "N/A";
    }
  };

  const currencySymbols = {
    AED: "د.إ", // UAE Dirham
    AFN: "؋", // Afghan Afghani
    ALL: "L", // Albanian Lek
    AMD: "֏", // Armenian Dram
    ANG: "ƒ", // Netherlands Antillean Guilder
    AOA: "Kz", // Angolan Kwanza
    ARS: "$", // Argentine Peso
    AUD: "A$", // Australian Dollar
    AWG: "ƒ", // Aruban Florin
    AZN: "₼", // Azerbaijani Manat
    BAM: "KM", // Bosnia and Herzegovina Convertible Mark
    BBD: "Bds$", // Barbadian Dollar
    BDT: "৳", // Bangladeshi Taka
    BGN: "лв", // Bulgarian Lev
    BHD: ".د.ب", // Bahraini Dinar
    BIF: "FBu", // Burundian Franc
    BMD: "$", // Bermudian Dollar
    BND: "B$", // Brunei Dollar
    BOB: "Bs.", // Bolivian Boliviano
    BRL: "R$", // Brazilian Real
    BSD: "$", // Bahamian Dollar
    BTN: "Nu.", // Bhutanese Ngultrum
    BWP: "P", // Botswana Pula
    BYN: "Br", // Belarusian Ruble
    BZD: "$", // Belize Dollar
    CAD: "C$", // Canadian Dollar
    CDF: "FC", // Congolese Franc
    CHF: "CHF", // Swiss Franc
    CLP: "$", // Chilean Peso
    CNY: "¥", // Chinese Yuan Renminbi
    COP: "$", // Colombian Peso
    CRC: "₡", // Costa Rican Colón
    CUP: "$MN", // Cuban Peso
    CVE: "$", // Cape Verdean Escudo
    CZK: "Kč", // Czech Koruna
    DJF: "Fdj", // Djiboutian Franc
    DKK: "kr", // Danish Krone
    DOP: "RD$", // Dominican Peso
    DZD: "د.ج", // Algerian Dinar
    EGP: "£", // Egyptian Pound
    ERN: "Nfk", // Eritrean Nakfa
    ETB: "Br", // Ethiopian Birr
    EUR: "€", // Euro
    FJD: "FJ$", // Fijian Dollar
    FKP: "£", // Falkland Islands Pound
    FOK: "kr", // Faroese Króna
    GBP: "£", // British Pound
    GEL: "₾", // Georgian Lari
    GGP: "£", // Guernsey Pound
    GHS: "GH₵", // Ghanaian Cedi
    GIP: "£", // Gibraltar Pound
    GMD: "D", // Gambian Dalasi
    GNF: "FG", // Guinean Franc
    GTQ: "Q", // Guatemalan Quetzal
    GYD: "$", // Guyanese Dollar
    HKD: "HK$", // Hong Kong Dollar
    HNL: "L", // Honduran Lempira
    HRK: "kn", // Croatian Kuna
    HTG: "G", // Haitian Gourde
    HUF: "Ft", // Hungarian Forint
    IDR: "Rp", // Indonesian Rupiah
    ILS: "₪", // Israeli New Shekel
    IMP: "£", // Isle of Man Pound
    INR: "₹", // Indian Rupee
    IQD: "ع.د", // Iraqi Dinar
    IRR: "﷼", // Iranian Rial
    ISK: "kr", // Icelandic Króna
    JEP: "£", // Jersey Pound
    JMD: "J$", // Jamaican Dollar
    JOD: "د.ا", // Jordanian Dinar
    JPY: "¥", // Japanese Yen
    KES: "KSh", // Kenyan Shilling
    KGS: "лв", // Kyrgyzstani Som
    KHR: "៛", // Cambodian Riel
    KID: "$", // Kiribati Dollar
    KMF: "CF", // Comorian Franc
    KRW: "₩", // South Korean Won
    KWD: "د.ك", // Kuwaiti Dinar
    KYD: "$", // Cayman Islands Dollar
    KZT: "₸", // Kazakhstani Tenge
    LAK: "₭", // Lao Kip
    LBP: "ل.ل", // Lebanese Pound
    LKR: "Rs", // Sri Lankan Rupee
    LRD: "$", // Liberian Dollar
    LSL: "M", // Lesotho Loti
    LYD: "ل.د", // Libyan Dinar
    MAD: "د.م.", // Moroccan Dirham
    MDL: "L", // Moldovan Leu
    MGA: "Ar", // Malagasy Ariary
    MKD: "ден", // Macedonian Denar
    MMK: "K", // Myanmar Kyat
    MNT: "₮", // Mongolian Tögrög
    MOP: "P", // Macanese Pataca
    MRU: "UM", // Mauritanian Ouguiya
    MUR: "₨", // Mauritian Rupee
    MVR: "Rf", // Maldivian Rufiyaa
    MWK: "MK", // Malawian Kwacha
    MXN: "Mex$", // Mexican Peso
    MYR: "RM", // Malaysian Ringgit
    MZN: "MT", // Mozambican Metical
    NAD: "$", // Namibian Dollar
    NGN: "₦", // Nigerian Naira
    NIO: "C$", // Nicaraguan Córdoba
    NOK: "kr", // Norwegian Krone
    NPR: "₨", // Nepalese Rupee
    NZD: "NZ$", // New Zealand Dollar
    OMR: "ر.ع.", // Omani Rial
    PAB: "B/.", // Panamanian Balboa
    PEN: "S/", // Peruvian Sol
    PGK: "K", // Papua New Guinean Kina
    PHP: "₱", // Philippine Peso
    PKR: "₨", // Pakistani Rupee
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    QAR: "ر.ق", // Qatari Riyal
    RON: "lei", // Romanian Leu
    RSD: "din", // Serbian Dinar
    RUB: "₽", // Russian Ruble
    RWF: "FRw", // Rwandan Franc
    SAR: "﷼", // Saudi Riyal
    SBD: "SI$", // Solomon Islands Dollar
    SCR: "₨", // Seychellois Rupee
    SDG: "ج.س.", // Sudanese Pound
    SEK: "kr", // Swedish Krona
    SGD: "S$", // Singapore Dollar
    SHP: "£", // Saint Helena Pound
    SLL: "Le", // Sierra Leonean Leone
    SOS: "Sh", // Somali Shilling
    SRD: "$", // Surinamese Dollar
    SSP: "£", // South Sudanese Pound
    STN: "Db", // São Tomé and Príncipe Dobra
    SYP: "£S", // Syrian Pound
    SZL: "L", // Swazi Lilangeni
    THB: "฿", // Thai Baht
    TJS: "ЅМ", // Tajikistani Somoni
    TMT: "T", // Turkmenistani Manat
    TND: "د.ت", // Tunisian Dinar
    TOP: "T$", // Tongan Paʻanga
    TRY: "₺", // Turkish Lira
    TTD: "TT$", // Trinidad and Tobago Dollar
    TVD: "$", // Tuvaluan Dollar
    TWD: "NT$", // New Taiwan Dollar
    TZS: "TSh", // Tanzanian Shilling
    UAH: "₴", // Ukrainian Hryvnia
    UGX: "USh", // Ugandan Shilling
    USD: "$", // US Dollar
    UYU: "$U", // Uruguayan Peso
    UZS: "so'm", // Uzbekistani Som
    VES: "Bs.", // Venezuelan Bolívar Soberano
    VND: "₫", // Vietnamese Đồng
    VUV: "VT", // Vanuatu Vatu
    WST: "WS$", // Samoan Tala
    XAF: "FCFA", // Central African CFA Franc
    XCD: "$", // East Caribbean Dollar
    XOF: "CFA", // West African CFA Franc
    XPF: "₣", // CFP Franc
    YER: "﷼", // Yemeni Rial
    ZAR: "R", // South African Rand
    ZMW: "ZK", // Zambian Kwacha
    ZWL: "$", // Zimbabwean Dollar
  };

  return (
    <Container className="mt-3">
      <Row className="justify-content-center mb-4 bg-light shadow">
        <h4 className="text-center text-uppercase ">Filter</h4>
        <Col md={3}>
          <Form.Group controlId="productFilter">
            <Typeahead
              id="productFilter"
              multiple
              options={productOptions}
              onChange={handleProductChange}
              placeholder="Filter/Choose products..."
              selected={selectedProducts}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="startDate">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="endDate">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
            />
          </Form.Group>
        </Col>

        <div className="mt-3">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th
                  onClick={() => handleDateRangeChange("yesterday")}
                  className="text-center"
                  role="button"
                >
                  Yesterday
                </th>
                <th
                  onClick={() => handleDateRangeChange("last7days")}
                  className="text-center"
                  role="button"
                >
                  Last 7 Days
                </th>
                <th
                  onClick={() => handleDateRangeChange("last30days")}
                  className="text-center"
                  role="button"
                >
                  Last 30 Days
                </th>
                <th
                  onClick={() => handleDateRangeChange("thisMonth")}
                  className="text-center"
                  role="button"
                >
                  This Month
                </th>
              </tr>
            </thead>
          </Table>
        </div>
      </Row>

      <Row className="justify-content-center">
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <h5 className="card-title">
                Total Sales{" "}
                {salesDirection === "up" ? (
                  <FaArrowUp color="green" size={15} />
                ) : (
                  <FaArrowDown color="red" size={15} />
                )}
              </h5>
              <p className="card-text ">{`R${totalSales}`} </p>
              <p className="card-text text-nowrap">
                <span style={{ color: "gray" }}>Today</span> {`R${todaySales}`}{" "}
                <span style={{ color: "gray", marginLeft: "10px" }}>
                  Yesterday
                </span>{" "}
                {`R${yesterdaySales}`}
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <h5 className="card-title">Number of Orders</h5>
              <p className="card-text">{numOrders}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <h5 className="card-title">Conversion Rate</h5>
              <p className="card-text">{`${conversionRate.toFixed(2)}%`}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <h5 className="card-title">Sales Trends</h5>
              <Line
                data={{
                  labels: salesByDate.map((entry) => entry.date),
                  datasets: [
                    {
                      label: "Total Sales",
                      data: salesByDate.map((entry) =>
                        convertPrice(entry.totalSales)
                      ),
                      fill: false,
                      borderColor: "rgb(75, 192, 192)",
                      tension: 0.1,
                    },
                  ],
                }}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                    x: {
                      type: "category",
                      labels: salesByDate.map((entry) => entry.date),
                    },
                  },
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card className="shadow-sm">
            <Card.Body>
              <h5 className="card-title">Product Performance</h5>
              <Bar
                data={{
                  labels: productData.map((product) => product.name),
                  datasets: [
                    {
                      label: "Sales per Product",
                      data: productData.map((product) => product.sales),
                      backgroundColor: "rgb(54, 162, 235)",
                      borderColor: "rgb(54, 162, 235)",
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                    x: {
                      type: "category",
                    },
                  },
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-sm border-0">
            <Card.Body>
              <h5 className="card-title text-center">Product Sales</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity Sold</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {productData.map((product) => (
                    <tr key={product.name}>
                      <td>{product.name}</td>
                      <td>{product.sales}</td>
                      {Object.keys(exchangeRates)
                        .filter((currency) => currency === country)
                        .map((currency) => (
                          <div key={currency}>
                            {currencySymbols[currency]
                              ? currencySymbols[currency]
                              : currency}{" "}
                            <td>{convertPrice(product.totalPrice)}</td>
                          </div>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Statistics;
