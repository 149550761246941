import React, { useState, useEffect } from "react";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import {
  auth,
  createUserWithEmailAndPassword,
  database,
  set,
  ref,
} from "../firebaseConfig";
import { sendEmail } from "../utils/SendEmail";

function Registration() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [userCountry, setUserCountry] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: name,
    email: email,
    contactNumber: number,
    message: "The user created account in the EcomElevate",
  });

  useEffect(() => {
    setFormData({
      name: name,
      email: email,
      contactNumber: number,
      message: "The user created account in the EcomElevate",
    });
  }, [name, email, number]);

  const [success, setSuccess] = useState(null);

  const passwordRequirements = [
    { regex: /.{8,}/, message: "Must be at least 8 characters long." },
    { regex: /[A-Z]/, message: "Must have one uppercase letter." },
    { regex: /[a-z]/, message: "Must have one lowercase letter." },
    { regex: /\d/, message: "Must have one number." },
    { regex: /[@$!%*?&]/, message: "Must have one special character." },
  ];

  useEffect(() => {
    // Fetch the list of countries
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const sortedCountries = data
          .map((country) => ({
            value: country.name.common,
            label: country.name.common,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(sortedCountries);
      });

    // Fetch the user's current location
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        setUserCountry(data.country_name);
        setCountry(data.country_name);
      });
  }, []);

  useEffect(() => {
    if (password === confirmPassword) {
      setPasswordMatchError("");
    } else {
      setPasswordMatchError("Passwords do not match.");
    }
  }, [password, confirmPassword]);

  function handlePasswordChange(event) {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const newPasswordErrors = passwordRequirements
      .filter((requirement) => !requirement.regex.test(newPassword))
      .map((requirement) => requirement.message);

    setPasswordErrors(newPasswordErrors);
  }

  function handleConfirmPasswordChange(event) {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
  }

  async function createAccount(email, password, name, lastname, number) {
    try {
      setLoading(true);
      setError("");
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Add user details to the Realtime Database
      await set(ref(database, "users/" + user.uid), {
        name,
        lastname,
        number,
        email,
        country,
      });

      navigate("/SetUpStore");
    } catch (err) {
      setError("Failed to create an account. " + err.message);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (passwordErrors.length > 0) {
      return;
    }

    if (password !== confirmPassword) {
      setPasswordMatchError("Passwords do not match.");
      return;
    }

    if (!termsAccepted) {
      setError("You must read & accept the terms and conditions.");
      return;
    }
    try {
      sendEmail(formData);
      setSuccess("Message sent successfully!");
      setFormData({ name: "", email: "", contactNumber: "", message: "" });
    } catch (error) {
      setError("Failed. Please try again later.");
    } finally {
      setLoading(false);
    }
    createAccount(email, password, name, lastname, number);
  }

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center">
      <div className="row w-100">
        <div
          className="col-md-6 d-none d-md-block p-5 position-relative text-white"
          style={{ backgroundColor: "#66d1be" }}
        >
          <div className="text-center my-5">
            <h1 className="display-4">Welcome</h1>
            <p className="lead">
              Welcome to our EcomElevate. Manage your store, track orders, and
              optimize your business operations. Let's get started!
            </p>
            <button
              className="btn btn-primary mt-4 sign-btn"
              onClick={() => navigate("/login")}
            >
              LOG IN
            </button>
          </div>
        </div>
        <div className="col-md-6 bg-light p-5">
          <h2 className="text-center mb-4">
            EcomElevate <br /> <small className="lead">Create Account</small>
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control custom-input"
                id="name"
                placeholder="John"
                autoComplete="off"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                className="form-control custom-input"
                id="lastname"
                placeholder="Smith"
                autoComplete="off"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="number">Contact Number</label>
              <input
                type="tel"
                className="form-control custom-input"
                id="number"
                placeholder="071 467 5197"
                autoComplete="off"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control custom-input"
                id="email"
                placeholder="Enter email address"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <Select
                id="country"
                options={countries}
                value={countries.find((option) => option.value === country)}
                onChange={(selectedOption) => setCountry(selectedOption.value)}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="password"
                style={{
                  color:
                    passwordErrors.length === 0 && password ? "green" : "black",
                }}
              >
                Password
              </label>
              <input
                type="password"
                className="form-control custom-input"
                id="password"
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              {passwordErrors.map((error, index) => (
                <small key={index} className="text-danger d-block">
                  {error}
                </small>
              ))}
            </div>
            <div className="form-group">
              <label
                htmlFor="confirmPassword"
                style={{
                  color: passwordMatchError
                    ? "red"
                    : confirmPassword && !passwordMatchError
                    ? "green"
                    : "black",
                }}
              >
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control custom-input"
                id="confirmPassword"
                placeholder="Password"
                autoComplete="off"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              {passwordMatchError && (
                <small className="text-danger">{passwordMatchError}</small>
              )}
            </div>
            <div className="form-group">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="terms"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <label className="form-check-label" htmlFor="terms">
                  I agree to the{" "}
                  <a href="/terms" target="_blank">
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a href="/privacy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary rounded-pill w-100"
                disabled={
                  loading ||
                  passwordErrors.length > 0 ||
                  password !== confirmPassword
                }
              >
                {loading ? (
                  <>
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </>
                ) : (
                  "Create Account"
                )}
              </button>
            </div>
            {error && (
              <div className="text-center text-danger mt-3">
                {error.replace(/Firebase: .*/, "")}
              </div>
            )}
            <div className="text-center mt-4 d-md-none">
              <p>
                Already have an account?{" "}
                <span
                  className="text-uppercase fw-bold"
                  onClick={() => navigate("/login")}
                >
                  LOGIN
                </span>{" "}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Registration;
