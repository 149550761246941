import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

function Nav() {
  const location = useLocation();
  const navigate = useNavigate();

  // Smooth scrolling function
  const smoothScroll = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // Handle click on navigation links
  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    smoothScroll(targetId);
  };

  // Define pages where the navigation list should be hidden
  const hiddenNavPages = ["/Login", "/Registration"];

  // Check if the current path is in the list of hidden pages
  const isNavHidden = hiddenNavPages.includes(location.pathname);

  useEffect(() => {
    // Clean-up effect
    return () => {};
  }, []); // Empty dependency array ensures this effect runs only once

  function home() {
    navigate("/");
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
      <a className="navbar-brand" href="#">
        <img src={require("../assets/Ecom-Icon.png")} alt="Logo" />
        <strong onClick={home}>EcomElevate</strong>
      </a>
      {!isNavHidden && (
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      )}
      <div
        className="collapse navbar-collapse justify-content-end justify-content-lg-center"
        id="navbarNav"
      >
        {!isNavHidden && (
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={home}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={(e) => handleNavClick(e, "about-section")}
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={(e) => handleNavClick(e, "package-section")}
              >
                Package
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={(e) => handleNavClick(e, "contact-section")}
              >
                Contact
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={(e) => navigate(`/news`)}
              >
                Articles
              </a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Nav;
