import React, { useState } from "react";
import FadeInSection from "../Components/AboutSupport";
import BusinessGrowth from "../Components/BusinessGrowth";
import Nav from "../Components/Nav";
import { useNavigate } from "react-router-dom";
import Package from "../Components/Package";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { sendEmail } from "../utils/SendEmail";
import NewsTiles from "../Components/News/NewsHome";

function Landing() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await sendEmail(formData);
      setSuccess("Message sent successfully!");
      setFormData({ name: "", email: "", contactNumber: "", message: "" });
    } catch (error) {
      setError("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  function handleLogin() {
    navigate("/Login");
  }

  function handleRegistration() {
    navigate("/Registration");
  }

  return (
    <>
      <Nav />
      <div className="hero-section d-flex align-items-center justify-content-center">
        <div className="text-center" id="home-section">
          <h1 className="hero-title">EcomElevate</h1>
          <p className="hero-subtitle">
            Unlock more customers | your Ecommerce store
          </p>

          <div>
            <button
              className="btn btn-primary rounded-pill mr-2"
              onClick={handleRegistration}
            >
              Create Account
            </button>
            <button
              className="btn btn-secondary rounded-pill"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </div>
      </div>

      <BusinessGrowth />

      <Package />

      <section className="py-5" id="about-section">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <h2 className="mb-4">
                Your Store, Your Pocket: We're Mobile-Friendly!
              </h2>
              <p className="lead">
                Revolutionize the way you manage your store with our
                mobile-friendly platform. No matter where you are, be in
                full control of your business, right from the palm of your hand.
              </p>
              <p>
                Seamlessly navigate through your inventory, manage orders
                on-the-go, and engage with customers in real-time - all with the
                convenience of your smartphone. With EcomElevate, your store is
                not just accessible, it's empowering. Take charge of your
                e-commerce journey, anytime, anywhere.
              </p>
              <p>
                Don't let limitations hold you back. Embrace mobility, embrace
                freedom. Experience the power of mobile-commerce with
                EcomElevate today!
              </p>
            </div>
          </div>
        </div>
      </section>

      <FadeInSection />

      <NewsTiles />

      <section className="py-5 bg-light" id="contact-section">
        <div className="container">
          <div className="row justify-content-center text-center mb-4">
            <div className="col-lg-8">
              <h2 className="mb-4">Get in Touch with Us</h2>
              <p className="lead">
                We’re here to help and answer any question you might have. We
                look forward to hearing from you.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="contactNumber"
                    placeholder="Your Number"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <div class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </>
                  ) : (
                    "Send Message"
                  )}
                </button>
                {error && <p className="text-danger mt-3">{error}</p>}
                {success && <p className="text-success mt-3">{success}</p>}
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <a
        href="https://wa.me/+27816155344"
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
    </>
  );
}

export default Landing;
