import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { database } from "../../firebaseConfig";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported

function NewsTiles() {
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const dbRef = ref(database, "news");
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const newsList = [];
          snapshot.forEach((childSnapshot) => {
            newsList.push({
              id: childSnapshot.key,
              ...childSnapshot.val(),
            });
          });
          setNewsData(newsList);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching news data: ", error);
      }
    };

    fetchNews();
  }, []);

  const handleNewsClick = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <section className="news-section py-5" id="news-section">
      <div className="container">
        <h2 className="text-center mb-4">Latest News</h2>
        <div className="row">
          <div className="col-12 overflow-auto">
            <div className="d-flex flex-nowrap">
              {newsData.map((news) => (
                <div
                  key={news.id}
                  className="col-lg-4 col-md-6 mb-4 flex-shrink-0"
                >
                  <div
                    className="card news-card"
                    onClick={() => handleNewsClick(news.id)}
                    style={{ cursor: "pointer", width: "350px" }}
                  >
                    <img
                      src={news.imageUrl}
                      className="card-img-top"
                      alt={news.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{news.title}</h5>
                      {/* <p className="card-text" dangerouslySetInnerHTML={{ __html:news.description.substring(0, 100)}} /> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsTiles;
