import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "../firebaseConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handlePasswordReset(event) {
    event.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Please check your inbox.");
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setError("Invalid email format. Please enter a valid email address.");
          break;
        case "auth/user-not-found":
          setError("No user found with this email address.");
          break;
        default:
          setError("Failed to send password reset email. Please try again later.");
          break;
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
        <a className="navbar-brand" href="#">
          <img src={require("../assets/Ecom-Icon.png")} alt="Logo" />
          <strong>EcomElevate</strong>
        </a>
      </nav>
      <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
        <div className="row w-100">
          <div className="col-md-6 d-none d-md-block p-5 position-relative" style={{ backgroundColor: "#66d1be", color: "white" }}>
            <div className="text-center my-5">
              <h1 className="display-4 text-white">Reset Password</h1>
              <p className="lead">
                Enter your email address and we'll send you a link to reset your password.
              </p>
            </div>
          </div>
          <div className="col-md-6 bg-light p-5">
            <h2 className="text-center mb-4">Forgot Password</h2>
            <form onSubmit={handlePasswordReset}>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="custom-input"
                  id="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {error && <p className="text-danger">{error}</p>}
              {message && <p className="text-success">{message}</p>}
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-block rounded-pill py-2 px-5 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </>
                  ) : (
                    "Send Reset Link"
                  )}
                </button>
              </div>
              <div className="text-center mt-4">
                <p>
                  Remember your password?{" "}
                  <span className="text-uppercase fw-bold" onClick={() => navigate("/login")}>
                    Login
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
