import emailjs from "emailjs-com";

export const sendEmail = async (formData) => {
  const SERVICE_ID = "service_t4edv6u";
  const TEMPLATE_ID = "template_6l5avk8";
  const USER_ID = "TXwIGmwA5vOvTLDBq";

  const emailParams = {
    to_name: "Ecom Elevate",
    from_name: formData.name,
    from_email: formData.email,
    from_phone: formData.contactNumber,
    message: formData.message,
  };

  try {
    const response = await emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      emailParams,
      USER_ID
    );
    return response;
  } catch (error) {
    console.error("Error sending email", error);
    throw error;
  }
};
