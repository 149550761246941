import { useEffect, useState, useContext } from "react";
import { database } from "../../firebaseConfig";
import { ref, onValue, update } from "firebase/database";
import { Container, Table, Button, Modal, Row, Col } from "react-bootstrap";
import { AuthContext } from "../../AuthContext";

const Orders = () => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser.uid;

  useEffect(() => {
    //   /${userId}
    const salesRef = ref(database, `users/${userId}/products/sales`);
    onValue(salesRef, (snapshot) => {
      const pendingOrdersList = [];
      const shippedOrdersList = [];
      snapshot.forEach((childSnapshot) => {
        const order = childSnapshot.val();
        const orderWithDate = {
          ...order,
          id: childSnapshot.key,
          date: new Date(order.date), // Convert date string to Date object
        };
        if (order.status === "Shipped") {
          shippedOrdersList.push(orderWithDate);
        } else {
          pendingOrdersList.push(orderWithDate);
        }
      });

      // Sort orders by date descending
      pendingOrdersList.sort((a, b) => b.date - a.date);
      shippedOrdersList.sort((a, b) => b.date - a.date);

      setPendingOrders(pendingOrdersList);
      setShippedOrders(shippedOrdersList);
    });
  }, []);

  const handleShipOrder = (orderId) => {
    const orderRef = ref(database, `users/${userId}/products/sales/${orderId}`);
    update(orderRef, { status: "Shipped" })
      .then(() => {
        setPendingOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
        setShippedOrders((prevOrders) =>
          [
            ...prevOrders,
            pendingOrders.find((order) => order.id === orderId),
          ].sort((a, b) => b.date - a.date)
        );
      })
      .catch((error) => {
        console.error("Error updating order status:", error);
      });
  };

  // Function to format date with day of the week, month, date, and year
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleShowDetails = (order) => {
    setSelectedOrder(order);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedOrder(null);
  };

  const renderTable = (orders, status) => (
    <Table striped bordered hover>
      <thead className="">
        <tr>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Status</th>
          <th>Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) =>
          order.products.map((product, index) => (
            <tr
              key={`${order.id}-${index}`}
              className={
                status === "Shipped" ? "table-success" : "table-danger"
              }
            >
              <td>{product.productId}</td>
              <td>{product.quantity}</td>
              <td>{product.price}</td>
              <td>{status}</td>
              <td>{index === 0 && formatDate(order.date)}</td>
              <td>
                <Button
                  variant="info"
                  className="fw-bold shadow-lg"
                  onClick={() => handleShowDetails(order)}
                >
                  Shiping Data
                </Button>

                {status === "Pending" && (
                  <Button
                    variant="light"
                    className="fw-bold shadow-lg ml-2"
                    onClick={() => handleShipOrder(order.id)}
                  >
                    Mark as Shipped
                  </Button>
                )}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );

  return (
    <Container className="mt-3">
      <h4 className="lead">Pending Orders</h4>
      {renderTable(pendingOrders, "Pending")}

      <h4 className="mt-5 lead">Shipped Orders</h4>
      {renderTable(shippedOrders, "Shipped")}

      <Modal show={showDetails} onHide={handleCloseDetails}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center text-uppercase">
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <>
              <Row>
                <Col className="text-right">
                  <strong>Customer Name</strong>
                </Col>
                <Col>{selectedOrder.customer.name}</Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <strong>Email Address</strong>
                </Col>
                <Col>{selectedOrder.customer.email}</Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <strong>Delivery Address</strong>
                </Col>
                <Col>{selectedOrder.customer.address}</Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <strong>Province</strong>
                </Col>
                <Col>{selectedOrder.customer.province}</Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <strong>Phone Number</strong>
                </Col>
                <Col>{selectedOrder.customer.number}</Col>
              </Row>
              {selectedOrder.status === "Shipped" ? (
                <h5 className="mt-4 text-uppercase">Shipped Product </h5>
              ) : (
                <>
                  <h5 className="mt-4 text-uppercase">Products to Send</h5>{" "}
                </>
              )}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.products.map((product, index) => (
                    <tr key={index}>
                      <td>{product.productId}</td>
                      <td>{product.quantity}</td>
                      <td>{product.price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Orders;
