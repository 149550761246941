import React, { useState, useEffect } from "react";

function FadeInSection() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.scrollY + window.innerHeight;
      const offset = document.querySelector(".py-5")?.offsetTop || 0;
      if (top > offset && !isVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible]);

  return (
    <section
      className={`py-5 bg-dark text-white ${
        isVisible ? "fade-in active" : "fade-in"
      }`}
    >
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <h2 className="mb-4">We Support You 24/7, Every Day</h2>
            <p className="lead">
              At EcomElevate, we understand that reliable support is essential
              for your peace of mind. That's why our dedicated team is available
              around the clock, every single day, to address your queries,
              resolve issues, and ensure your ecommerce experience is seamless.
            </p>
            <p>
              Whether it's a question about your store issues, assistance with
              maintenance, or troubleshooting an unexpected problem, our
              knowledgeable support staff is just a call or click away. We're
              here to support you at any hour, because your satisfaction is our
              priority.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FadeInSection;
