import { initializeApp } from "firebase/app";
import { getAuth,createUserWithEmailAndPassword,signInWithEmailAndPassword,sendPasswordResetEmail } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyD8dwtq9ey2JglZGYVYukFVbUo_04z4DXU",
  authDomain: "ecomlevate.firebaseapp.com",
  projectId: "ecomlevate",
  storageBucket: "ecomlevate.appspot.com",
  messagingSenderId: "348195231256",
  appId: "1:348195231256:web:81ebe77672caa1b89bd56d",
  measurementId: "G-DLRMMQ8DFG"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { ref,set,auth, database, storage,createUserWithEmailAndPassword,signInWithEmailAndPassword,sendPasswordResetEmail };
