import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../firebaseConfig";
import Nav from "../Components/Nav";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
const navigateForgotpwd = () =>{
  navigate("/ForgotPassword");
}
  async function handleLogin(event) {
    event.preventDefault();
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/home");
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setError("Invalid email format. Please enter a valid email address.");
          break;
        case "auth/user-disabled":
          setError("Your account has been disabled. Please contact support.");
          break;
        case "auth/user-not-found":
        case "auth/wrong-password":
          setError("Invalid email or password. Please try again.");
          break;
        default:
          setError("Login failed. Please try again later.");
          break;
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
        <a className="navbar-brand" href="#">
          <img src={require("../assets/Ecom-Icon.png")} alt="Logo" />
          <strong>EcomElevate</strong>
        </a>
      </nav>
      <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
        <div className="row w-100">
          <div
            className="col-md-6 d-none d-md-block p-5 position-relative"
            style={{ backgroundColor: "#66d1be", color: "white" }}
          >
            <div className="text-center my-5">
              <h1 className="display-4 text-white">Welcome</h1>
              <p className="lead">
                Welcome to the admin portal of our ecommerce platform. Here, you
                have the power to manage your store, track orders, and optimize
                your business operations. Let's dive in and see how your online
                business is performing today.
              </p>
              <button
                className="btn btn-primary mt-4 sign-btn d-none d-md-block"
                onClick={() => navigate("/Registration")}
              >
                SIGN IN
              </button>
            </div>
          </div>
          <div className="col-md-6 bg-light p-5">
            <h2 className="text-center mb-4">Login</h2>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="custom-input"
                  id="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="custom-input"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {error && <p className="text-danger">{error}</p>}
              <a href="#" className="forgot-password fw-bold" onClick={navigateForgotpwd}>
                Forgot password?
              </a>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-block rounded-pill py-2 px-5 w-100"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <div class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <div className="text-center mt-4 d-md-none">
                <p>
                  Don't have account?{" "}
                  <span
                    className="text-uppercase fw-bold"
                    onClick={() => navigate("/Registration")}
                  >
                    SIGN IN
                  </span>{" "}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
