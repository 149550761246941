import React, { useContext, useState, useEffect } from "react";
import { ref, get, child, set, onValue } from "firebase/database"; // Updated import for Firebase
import { database } from "../firebaseConfig";
import { AuthContext } from "../AuthContext";
import packageOne from "../assets/packageOne.png";
import packageThree from "../assets/packageThree.jpg";
import packageTwo from "../assets/packageTwo.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const StoreLayout = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [hideDiv, setHideDiv] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isDemo, setIsDemo] = useState(false);
  const [productName, setProductName] = useState(
    "EcomElevate-subscribe-Purchase"
  );

  const [amount, setCheckoutTotal] = useState(160);
  const [UserEmail, setUserEmail] = useState("support@ecomlevate.com");
  const [packageSelected, setPackageSelected] = useState(false);
  const [DemoActive, setDemoActive] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [exchangeRates, setExchangeRates] = useState({});
  const [country, setCountry] = useState("");
  const [payFastview, setpayFastview] = useState("");

  useEffect(() => {
    fetchGeolocationAndRates();
  }, []);

  const fetchGeolocationAndRates = async () => {
    try {
      // Step 1: Fetch geolocation data and currency information
      const ipapiResponse = await fetch("https://ipapi.co/json/");
      if (!ipapiResponse.ok) {
        throw new Error("Failed to fetch geolocation data");
      }
      const ipapiData = await ipapiResponse.json();
      const currencyCode = ipapiData.currency;
      setCountry(ipapiData.currency);
      // Step 2: Fetch exchange rates based on detected currency
      const ratesResponse = await fetch(
        `https://api.exchangerate-api.com/v4/latest/${currencyCode}`
      );
      if (!ratesResponse.ok) {
        throw new Error("Failed to fetch exchange rates");
      }
      const ratesData = await ratesResponse.json();
      setExchangeRates(ratesData.rates);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const convertPrice = (price, currency) => {
    if (exchangeRates[currency]) {
      return (price * exchangeRates[currency]).toFixed(2);
    } else {
      return exchangeRates[currency];
    }
  };

  // console.log("The pprice",convertPrice)
  const packages = {
    batteryAndSolar: [
      {
        title: "Flash Plus",
        description: "Get one page Ecommerce/online store",
        price: "160",
        subtitle: "10 items to upload",
        hours: "custom free domain | free hosting",
        componets: [
          "No reviews for each product/item",
          "No cart for the store",
          "Start uploading your items in your store today",
        ],
        appliances: [
          "No reviews for each product",
          "Ready ecommerce store today",
          "Your own store name in words",
        ],
        backgroundImages: [packageOne],
      },
      {
        title: "Spark",
        description:
          "Ecommerce/online store. Search functionality will make it effortless for customers to find what they're looking for.",
        price: "200",
        subtitle: "30 items to upload",
        hours: "custom free domain | free hosting",
        componets: [
          "20 reviews for each product/item",
          "No cart for the store",
          "Start uploading your items in your store today",
        ],
        appliances: [
          "20 reviews for each product\n",
          "Ready ecommerce store\n",
          "Your own logo",
        ],
        backgroundImages: [packageOne],
      },
      {
        title: "Glow",
        description:
          "Ecommerce/online store. Clear categories, search functionality and cart will make it effortless for customers to find what they're looking for.",
        price: "250",
        subtitle: "Unlimited items to upload",
        hours: "custom free domain | free hosting",
        appliances: [
          "unlimited reviews\n",
          "designed ecommerce store\n",
          "Your own logo",
        ],
        backgroundImages: [packageThree, packageThree],
      },
      {
        title: "Shine",
        description:
          "Unlock Your Dream Store: Any Design, Your Perfect Online Store, Custom-Made for You!",
        price: "350",
        subtitle: "Unlimited items to upload",
        hours: "custom free domain | free hosting",
        compo: ["Start Creating Your Perfect Online Store Today!"],
        comp: "Custom features",
        appliances: [
          "Full custom store/ecommerce\n",
          "Your own free Domain name. Free hosting\n",
          "unlimited reviews for each product\n",
        ],
        backgroundImages: [packageOne, packageTwo],
      },
    ],
  };

  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(packages.batteryAndSolar.length).fill(0)
  );

  useEffect(() => {
    const intervals = packages.batteryAndSolar.map((pkg, pkgIndex) => {
      return setInterval(() => {
        setCurrentImageIndices((prevIndices) => {
          const newIndices = [...prevIndices];
          const imagesLength =
            packages.batteryAndSolar[pkgIndex].backgroundImages.length;
          newIndices[pkgIndex] =
            newIndices[pkgIndex] === imagesLength - 1
              ? 0
              : newIndices[pkgIndex] + 1;
          return newIndices;
        });
      }, Math.random() * 5000 + 8000); // Change image every 2-5 seconds (randomly)
    });

    return () => intervals.forEach(clearInterval);
  }, [packages]);

  useEffect(() => {
    // Function to check Firebase for selected package
    const checkSelectedPackage = async () => {
      try {
        const userId = currentUser.uid;
        const snapshot = await get(
          child(ref(database), `users/${userId}/selectedPackage`)
        );
        if (snapshot.exists()) {
          const selectedPkg = snapshot.val().package;
          setSelectedPackage(selectedPkg);
          setPackageSelected(true); // Set packageSelected to true if a package is found
        }
      } catch (error) {
        console.error("Error fetching selected package from Firebase:", error);
      }
    };

    if (currentUser) {
      checkSelectedPackage(); // Call the function when currentUser is available
    }
  }, [currentUser]);

  const handleOpenModal = (pkg, demo) => {
    setModalContent(pkg);
    setModalVisible(true);
  };

  //For updating the checkout amount
  useEffect(() => {
    if (modalContent && modalVisible) {
      const currency = Object.keys(exchangeRates).find(
        (currency) => currency === country
      );
      if (currency) {
        const convertedPrice = convertPrice(modalContent.price, currency);
        setCheckoutTotal(convertedPrice);
      }
    }
  }, [modalContent, modalVisible, exchangeRates, country]);

  const subscribe = async () => {
    // dispatch(showLoader());
    setModalVisible(false);
    setHideDiv(false);
    try {
      const response = await fetch(
        "https://domain-checker-api-git-main-givens-projects-48cf6823.vercel.app/initiate-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productName,
            amount,
            UserEmail,
          }),
        }
      );

      if (response.ok) {
        const paymentData = await response.json();
        //console.log("Initiated payment:", paymentData);

        setpayFastview(paymentData.paymentId.uuid);
      } else {
        throw new Error("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      // dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (payFastview) {
      // Remove existing viewport meta tags
      const existingViewportMetaTags = document.head.querySelectorAll(
        'meta[name="viewport"]'
      );
      existingViewportMetaTags.forEach((tag) => tag.remove());

      // Add viewport meta tag for PayFast
      const payFastMeta = document.createElement("meta");
      payFastMeta.name = "viewport";
      payFastMeta.content = "width=device-width, initial-scale=1.0";
      document.head.appendChild(payFastMeta);

      // Create a container for PayFast view
      const payFastContainer = document.createElement("div");
      payFastContainer.style.zIndex = "999";
      document.body.appendChild(payFastContainer);

      const script = document.createElement("script");
      script.src = "https://www.payfast.co.za/onsite/engine.js";
      script.async = true;
      script.onload = () => {
        if (window.payfast_do_onsite_payment) {
          window.payfast_do_onsite_payment({ uuid: payFastview }, (result) => {
            // Update viewport meta tag based on the payment result

            if (result === true) {
              handleSelectPackage(selectedPackage, isDemo);
            }
          });
        } else {
          console.error("PayFast script did not load correctly.");
        }
      };
      script.onerror = () => {
        console.error("Failed to load PayFast script.");
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(payFastContainer);
        // Remove PayFast viewport meta tag
        document.head.removeChild(payFastMeta);
      };
    }
  }, [payFastview]);

  const handleSelectPackage = (pkg, demo) => {
    setSelectedPackage(pkg);
    setIsDemo(demo);

    // Save the selected package and demo status to Firebase
    const userId = currentUser.uid;
    set(ref(database, `users/${userId}/selectedPackage`), {
      package: pkg,
      isDemo: false,
    })
      .then(() => {
        navigate("/home");
      })
      .catch((error) => {
        console.error("Error saving selected package:", error);
      });
  };

  const DemoSelectPackage = (pkg, demo) => {
    setSelectedPackage(pkg);
    setIsDemo(demo);

    // Save the selected package and demo status to Firebase
    const userId = currentUser.uid;
    set(ref(database, `users/${userId}/selectedPackage`), {
      isDemo: true,
    })
      .then(() => {
        navigate("/home");
      })
      .catch((error) => {
        console.error("Error saving selected package:", error);
      });
  };

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}/selectedPackage`);

      // Fetch user data from Firebase on component mount
      const fetchUserData = async () => {
        try {
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
              // Check if selectedPackage exists and set the package title

              setDemoActive(userData.isDemo);
            }
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  // Sort the packages by price
  const sortedPackages = [...packages.batteryAndSolar].sort(
    (a, b) => a.price - b.price
  );

  const currencySymbols = {
    AED: "د.إ", // UAE Dirham
    AFN: "؋", // Afghan Afghani
    ALL: "L", // Albanian Lek
    AMD: "֏", // Armenian Dram
    ANG: "ƒ", // Netherlands Antillean Guilder
    AOA: "Kz", // Angolan Kwanza
    ARS: "$", // Argentine Peso
    AUD: "A$", // Australian Dollar
    AWG: "ƒ", // Aruban Florin
    AZN: "₼", // Azerbaijani Manat
    BAM: "KM", // Bosnia and Herzegovina Convertible Mark
    BBD: "Bds$", // Barbadian Dollar
    BDT: "৳", // Bangladeshi Taka
    BGN: "лв", // Bulgarian Lev
    BHD: ".د.ب", // Bahraini Dinar
    BIF: "FBu", // Burundian Franc
    BMD: "$", // Bermudian Dollar
    BND: "B$", // Brunei Dollar
    BOB: "Bs.", // Bolivian Boliviano
    BRL: "R$", // Brazilian Real
    BSD: "$", // Bahamian Dollar
    BTN: "Nu.", // Bhutanese Ngultrum
    BWP: "P", // Botswana Pula
    BYN: "Br", // Belarusian Ruble
    BZD: "$", // Belize Dollar
    CAD: "C$", // Canadian Dollar
    CDF: "FC", // Congolese Franc
    CHF: "CHF", // Swiss Franc
    CLP: "$", // Chilean Peso
    CNY: "¥", // Chinese Yuan Renminbi
    COP: "$", // Colombian Peso
    CRC: "₡", // Costa Rican Colón
    CUP: "$MN", // Cuban Peso
    CVE: "$", // Cape Verdean Escudo
    CZK: "Kč", // Czech Koruna
    DJF: "Fdj", // Djiboutian Franc
    DKK: "kr", // Danish Krone
    DOP: "RD$", // Dominican Peso
    DZD: "د.ج", // Algerian Dinar
    EGP: "£", // Egyptian Pound
    ERN: "Nfk", // Eritrean Nakfa
    ETB: "Br", // Ethiopian Birr
    EUR: "€", // Euro
    FJD: "FJ$", // Fijian Dollar
    FKP: "£", // Falkland Islands Pound
    FOK: "kr", // Faroese Króna
    GBP: "£", // British Pound
    GEL: "₾", // Georgian Lari
    GGP: "£", // Guernsey Pound
    GHS: "GH₵", // Ghanaian Cedi
    GIP: "£", // Gibraltar Pound
    GMD: "D", // Gambian Dalasi
    GNF: "FG", // Guinean Franc
    GTQ: "Q", // Guatemalan Quetzal
    GYD: "$", // Guyanese Dollar
    HKD: "HK$", // Hong Kong Dollar
    HNL: "L", // Honduran Lempira
    HRK: "kn", // Croatian Kuna
    HTG: "G", // Haitian Gourde
    HUF: "Ft", // Hungarian Forint
    IDR: "Rp", // Indonesian Rupiah
    ILS: "₪", // Israeli New Shekel
    IMP: "£", // Isle of Man Pound
    INR: "₹", // Indian Rupee
    IQD: "ع.د", // Iraqi Dinar
    IRR: "﷼", // Iranian Rial
    ISK: "kr", // Icelandic Króna
    JEP: "£", // Jersey Pound
    JMD: "J$", // Jamaican Dollar
    JOD: "د.ا", // Jordanian Dinar
    JPY: "¥", // Japanese Yen
    KES: "KSh", // Kenyan Shilling
    KGS: "лв", // Kyrgyzstani Som
    KHR: "៛", // Cambodian Riel
    KID: "$", // Kiribati Dollar
    KMF: "CF", // Comorian Franc
    KRW: "₩", // South Korean Won
    KWD: "د.ك", // Kuwaiti Dinar
    KYD: "$", // Cayman Islands Dollar
    KZT: "₸", // Kazakhstani Tenge
    LAK: "₭", // Lao Kip
    LBP: "ل.ل", // Lebanese Pound
    LKR: "Rs", // Sri Lankan Rupee
    LRD: "$", // Liberian Dollar
    LSL: "M", // Lesotho Loti
    LYD: "ل.د", // Libyan Dinar
    MAD: "د.م.", // Moroccan Dirham
    MDL: "L", // Moldovan Leu
    MGA: "Ar", // Malagasy Ariary
    MKD: "ден", // Macedonian Denar
    MMK: "K", // Myanmar Kyat
    MNT: "₮", // Mongolian Tögrög
    MOP: "P", // Macanese Pataca
    MRU: "UM", // Mauritanian Ouguiya
    MUR: "₨", // Mauritian Rupee
    MVR: "Rf", // Maldivian Rufiyaa
    MWK: "MK", // Malawian Kwacha
    MXN: "Mex$", // Mexican Peso
    MYR: "RM", // Malaysian Ringgit
    MZN: "MT", // Mozambican Metical
    NAD: "$", // Namibian Dollar
    NGN: "₦", // Nigerian Naira
    NIO: "C$", // Nicaraguan Córdoba
    NOK: "kr", // Norwegian Krone
    NPR: "₨", // Nepalese Rupee
    NZD: "NZ$", // New Zealand Dollar
    OMR: "ر.ع.", // Omani Rial
    PAB: "B/.", // Panamanian Balboa
    PEN: "S/", // Peruvian Sol
    PGK: "K", // Papua New Guinean Kina
    PHP: "₱", // Philippine Peso
    PKR: "₨", // Pakistani Rupee
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    QAR: "ر.ق", // Qatari Riyal
    RON: "lei", // Romanian Leu
    RSD: "din", // Serbian Dinar
    RUB: "₽", // Russian Ruble
    RWF: "FRw", // Rwandan Franc
    SAR: "﷼", // Saudi Riyal
    SBD: "SI$", // Solomon Islands Dollar
    SCR: "₨", // Seychellois Rupee
    SDG: "ج.س.", // Sudanese Pound
    SEK: "kr", // Swedish Krona
    SGD: "S$", // Singapore Dollar
    SHP: "£", // Saint Helena Pound
    SLL: "Le", // Sierra Leonean Leone
    SOS: "Sh", // Somali Shilling
    SRD: "$", // Surinamese Dollar
    SSP: "£", // South Sudanese Pound
    STN: "Db", // São Tomé and Príncipe Dobra
    SYP: "£S", // Syrian Pound
    SZL: "L", // Swazi Lilangeni
    THB: "฿", // Thai Baht
    TJS: "ЅМ", // Tajikistani Somoni
    TMT: "T", // Turkmenistani Manat
    TND: "د.ت", // Tunisian Dinar
    TOP: "T$", // Tongan Paʻanga
    TRY: "₺", // Turkish Lira
    TTD: "TT$", // Trinidad and Tobago Dollar
    TVD: "$", // Tuvaluan Dollar
    TWD: "NT$", // New Taiwan Dollar
    TZS: "TSh", // Tanzanian Shilling
    UAH: "₴", // Ukrainian Hryvnia
    UGX: "USh", // Ugandan Shilling
    USD: "$", // US Dollar
    UYU: "$U", // Uruguayan Peso
    UZS: "so'm", // Uzbekistani Som
    VES: "Bs.", // Venezuelan Bolívar Soberano
    VND: "₫", // Vietnamese Đồng
    VUV: "VT", // Vanuatu Vatu
    WST: "WS$", // Samoan Tala
    XAF: "FCFA", // Central African CFA Franc
    XCD: "$", // East Caribbean Dollar
    XOF: "CFA", // West African CFA Franc
    XPF: "₣", // CFP Franc
    YER: "﷼", // Yemeni Rial
    ZAR: "R", // South African Rand
    ZMW: "ZK", // Zambian Kwacha
    ZWL: "$", // Zimbabwean Dollar
  };

  // Render only the Shine package if it's selected, otherwise render all packages
  if (packageSelected) {
    return (
      <div className="container-fluid py-5">
        {/* Render only the Shine package */}
        <div className="sticky-top bg-white py-3">
          <div className="text-center mb-5">
            <h1 className="display-4 fw-bold">Shine Package</h1>
            <p className="lead text-dark">
              Realize your ideal store—the one you've envisioned and dreamed of.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-stretch">
          {sortedPackages.map(
            (pkg, index) =>
              pkg.title === "Shine" && (
                <div
                  className="col-md-6 col-lg-4 mb-4 d-flex rounded"
                  key={index}
                >
                  <div
                    className="card h-100 border-0 shadow-sm flex-fill position-relative"
                    style={{
                      backgroundImage: `url(${
                        pkg.backgroundImages[currentImageIndices[index]]
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="overlay"></div>
                    <div className="card-body d-flex flex-column position-relative">
                      <h1 className="card-title w-100 text-center text-light">
                        {pkg.title}
                      </h1>
                      <p className="card-text text-light lead">
                        {pkg.description}
                      </p>
                      <h5 className="card-text text-light text-uppercase">
                        {pkg.comp}
                      </h5>

                      <h1 className="card-text text-light">
                        <strong>R {pkg.price}</strong>
                        <small>p/m</small>
                      </h1>
                      <hr />

                      <div className="mt-auto d-flex justify-content-center mt-3">
                        <button
                          className="btn btn-outline-light w-100 text-uppercase fw-bold"
                          onClick={() => handleOpenModal(pkg, false)}
                        >
                          Select Package
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        {modalVisible && (
          <div className="modal d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center w-100 text-uppercase">
                    {modalContent.title}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setModalVisible(false)}
                  ></button>
                </div>
                <div className="modal-body w-100 text-center">
                  <img
                    src={
                      modalContent.backgroundImages[
                        currentImageIndices[
                          packages.batteryAndSolar.findIndex(
                            (pkg) => pkg.title === modalContent.title
                          )
                        ]
                      ]
                    }
                    alt={modalContent.title}
                    className="img-fluid mb-3"
                  />
                  <p className="lead">{modalContent.description}</p>
                  <h4 className="fw-bold">{modalContent.comp}</h4>
                  {/* <p className="lead">{modalContent.compo}</p> */}
                  <h1 className="fw-bold">
                    <strong>R {modalContent.price}</strong>
                    <small>p/m</small>
                  </h1>
                  <p className="lead">{modalContent.subtitle}</p>

                  <h6 className="fw-bold text-uppercase">What you get</h6>
                  <ul className="list-unstyled">
                    {modalContent.appliances.map((appliance, index) => (
                      <li key={index} className="text-start text-center">
                        {appliance}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="modal-footer d-flex justify-content-center">
                  <button
                    onClick={subscribe}
                    className="btn btn-success rounded me-2 flex-grow-1 h-5"
                  >
                    Pay Now
                  </button>
                  {!DemoActive && (
                    <button
                      onClick={DemoSelectPackage}
                      className="btn btn-secondary rounded ms-2 flex-grow-1 h-5"
                    >
                      Start Demo
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    // Render all packages
    return (
      <div className="container-fluid py-5">
        {hideDiv && (
          <>
            <div className="sticky-top bg-white py-3">
              <div className="text-center mb-5">
                <h1 className="display-4 fw-bold">Choose Your Package</h1>
                <p className="lead text-dark">
                  Select the best package for your store and start your journey
                  today.
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-stretch">
              {sortedPackages.map((pkg, index) => (
                <div
                  className="col-md-6 col-lg-4 mb-4 d-flex rounded"
                  key={index}
                >
                  <div
                    className="card h-100 border-0 shadow-sm flex-fill position-relative"
                    style={{
                      backgroundImage: `url(${
                        pkg.backgroundImages[currentImageIndices[index]]
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="overlay"></div>
                    <div className="card-body d-flex flex-column position-relative">
                      <h1 className="card-title w-100 text-center text-light">
                        {pkg.title}
                      </h1>
                      <p className="card-text text-light lead">
                        {pkg.description}
                      </p>
                      <h5 className="card-text text-light text-uppercase">
                        {pkg.comp}
                      </h5>

                      <h1 className="card-text text-light">
                        {/* {pkg.price} */}
                        {Object.keys(exchangeRates)
                          .filter((currency) => currency === country)
                          .map((currency) => (
                            <div key={currency}>
                              {currencySymbols[currency]
                                ? currencySymbols[currency]
                                : currency}{" "}
                              {convertPrice(pkg.price, currency)}{" "}
                              <small>p/m</small>
                            </div>
                          ))}
                      </h1>
                      <hr />

                      <div className="mt-auto d-flex justify-content-between mt-3">
                        <button
                          className="btn btn-outline-light  w-100 text-uppercase fw-bold"
                          onClick={() => handleOpenModal(pkg, false)}
                        >
                          Select Package
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {modalVisible && (
              <div className="modal d-block" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-fullscreen">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center w-100 text-uppercase">
                        {modalContent.title}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setModalVisible(false)}
                      ></button>
                    </div>
                    <div className="modal-body w-100 text-center">
                      <img
                        src={
                          modalContent.backgroundImages[
                            currentImageIndices[
                              packages.batteryAndSolar.findIndex(
                                (pkg) => pkg.title === modalContent.title
                              )
                            ]
                          ]
                        }
                        alt={modalContent.title}
                        className="img-fluid mb-3"
                      />
                      <p className="lead">{modalContent.description}</p>
                      <h4 className="fw-bold">{modalContent.comp}</h4>
                      {/* <p className="lead">{modalContent.compo}</p> */}
                      <h1 className="fw-bold">
                        {Object.keys(exchangeRates)
                          .filter((currency) => currency === country)
                          .map((currency) => (
                            <div key={currency}>
                              {currencySymbols[currency]
                                ? currencySymbols[currency]
                                : currency}{" "}
                              {convertPrice(modalContent.price, currency)}{" "}
                              <small>p/m</small>
                            </div>
                          ))}
                      </h1>
                      <p className="lead">{modalContent.subtitle}</p>

                      <h6 className="fw-bold text-uppercase">What you get</h6>
                      <ul className="list-unstyled">
                        {modalContent.appliances.map((appliance, index) => (
                          <li key={index} className="text-start text-center">
                            {appliance}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="modal-footer d-flex justify-content-center">
                      <button
                        onClick={subscribe}
                        className="btn btn-success rounded me-2 flex-grow-1 h-5"
                      >
                        PAY NOW
                      </button>
                      {!DemoActive && (
                        <button
                          onClick={DemoSelectPackage}
                          className="btn btn-secondary rounded ms-2 flex-grow-1 h-5"
                        >
                          2 WEEK TRIAL
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
};

export default StoreLayout;
