import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { ref, onValue, update } from "firebase/database";
import { database } from "../../firebaseConfig";
import { FaUser } from "react-icons/fa";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Settings = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    number: "",
    storeName: "",
    domain: "",
  });
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isStoreNameSet, setIsStoreNameSet] = useState(false);
  const [storeNameChangeCount, setStoreNameChangeCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [categoryCount, setCategoryCount] = useState(0);
  const [domainAvailability, setDomainAvailability] = useState(null);
  const [domainCheckInProgress, setDomainCheckInProgress] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);

      // Fetch user data from Firebase on component mount
      const fetchUserData = async () => {
        try {
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
              setUserInfo(userData);
              setIsStoreNameSet(!!userData.storeName);
              setStoreNameChangeCount(userData.storeNameChangeCount || 0);
              setCategories(userData.categories || []);

              // Check if selectedPackage exists and set the package title
              if (
                userData.selectedPackage &&
                userData.selectedPackage.package
              ) {
                setPackageTitle(userData.selectedPackage.package.title);
              }
            }
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  useEffect(() => {
    // Count the number of categories
    setCategoryCount(categories.length);
  }, [categories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleUpdateUserInfo = (e) => {
    e.preventDefault();
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      update(userRef, userInfo)
        .then(() => {
          console.log("User information updated successfully:", userInfo);
          setShowEditModal(false);
        })
        .catch((error) => {
          console.error("Error updating user information:", error);
        });
    }
  };

  const handleSetStoreName = (e) => {
    e.preventDefault();
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      if (!isStoreNameSet) {
        setIsStoreNameSet(true);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
        update(userRef, {
          storeName: userInfo.storeName,
          storeNameChangeCount: 1,
        })
          .then(() => {
            console.log("Store name updated successfully:", userInfo.storeName);
          })
          .catch((error) => {
            console.error("Error updating store name:", error);
          });
      } else if (storeNameChangeCount === 1) {
        // Charge R500 for subsequent changes
        // Implement payment logic here
        console.log("Charge R500 for store name change.");
      }
    }
  };

  const handleUpgradePlan = (newPlan) => {
    // setSelectedPlan(newPlan);
    navigate("/StoreLayout");
    // Update plan i`   nformation in Firebase if needed
  };

  const handleModalShow = () => {
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (newCategory.trim() === "") return;

    // Check if the plan is "Glow" and category count is 3
    if (selectedPlan === "Glow" && categoryCount === 3) {
      alert(
        "You have reached the maximum limit of 3 categories for Glow plan."
      );
      return;
    }

    const updatedCategories = [...categories, newCategory];
    setCategories(updatedCategories);
    setNewCategory("");

    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      const productsRef = ref(database, `products/${userId}/categories`);

      // Update categories in user data
      update(userRef, { categories: updatedCategories })
        .then(() => {
          console.log(
            "Categories updated successfully in user data:",
            updatedCategories
          );
        })
        .catch((error) => {
          console.error("Error updating categories in user data:", error);
        });

      // Update categories in products data
      update(productsRef, { categories: updatedCategories })
        .then(() => {
          console.log(
            "Categories updated successfully in products data:",
            updatedCategories
          );
        })
        .catch((error) => {
          console.error("Error updating categories in products data:", error);
        });
    }
  };

  const handleRemoveCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);

    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      const productsRef = ref(database, `products/${userId}/categories`);

      // Update categories in user data
      update(userRef, { categories: updatedCategories })
        .then(() => {
          console.log("Category removed successfully in user data");
        })
        .catch((error) => {
          console.error("Error removing category in user data:", error);
        });

      // Update categories in products data
      update(productsRef, { categories: updatedCategories })
        .then(() => {
          console.log("Category removed successfully in products data");
        })
        .catch((error) => {
          console.error("Error removing category in products data:", error);
        });
    }
  };

  const handleCheckDomain = async (e) => {
    e.preventDefault();
    setDomainCheckInProgress(true);
    try {
      const response = await axios.get(
        `http://localhost:3000/check-domain?domain=${userInfo.domain}`
      );
      setDomainAvailability(response.data.available);
      setDomainCheckInProgress(false);
    } catch (error) {
      console.error("Error checking domain availability:", error);
      setDomainCheckInProgress(false);
    }
  };

  const handleAcceptDomain = () => {
    if (currentUser && domainAvailability) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      update(userRef, { domain: userInfo.domain })
        .then(() => {
          console.log("Domain updated successfully:", userInfo.domain);
          setDomainAvailability(null); // Reset domain availability
        })
        .catch((error) => {
          console.error("Error updating domain:", error);
        });
    }
  };

  return (
    <Container className="mt-3">
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="link"
          onClick={handleModalShow}
          className="edit-button ms-auto me-4"
        >
          <FaUser size={24} color="black" />
        </Button>
      </div>

      <Row className="mb-4">
        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Body>
              <h4 className="w-100 text-center text-uppercase">
                Set Store Name
              </h4>
              {isStoreNameSet ? (
                <Alert variant="danger">
                  To change the store name, a fee of R500 will be charged.
                </Alert>
              ) : (
                <Alert variant="warning">
                  Caution: You can set the store name only once. Future changes
                  will incur a fee of R500.
                </Alert>
              )}

              <Form onSubmit={handleCheckDomain} className="mt-4">
                <Form.Group controlId="formDomain" className="mb-3">
                  <Form.Label className="fw-bold">URL Link/Domain</Form.Label>
                  <Form.Control
                    type="text"
                    name="domain"
                    placeholder="e.g www.storename.co.za/com/org"
                    value={userInfo.domain}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="secondary"
                  className="w-100 round "
                  disabled={domainCheckInProgress}
                >
                  {domainCheckInProgress ? "Checking..." : "Check Domain"}
                </Button>
              </Form>

              {domainAvailability !== null && (
                <div className="mt-3">
                  {domainAvailability ? (
                    <>
                      <Alert variant="success">Domain is available!</Alert>
                      <Button variant="secondary" onClick={handleAcceptDomain}>
                        Accept Domain
                      </Button>
                    </>
                  ) : (
                    <Alert variant="danger">
                      Domain/URL Link is not available.
                    </Alert>
                  )}
                </div>
              )}

              <Form onSubmit={handleSetStoreName}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="storeName"
                    value={userInfo.storeName}
                    onChange={handleInputChange}
                    disabled={isStoreNameSet && storeNameChangeCount === 1}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="secondary"
                  className="w-100 round"
                  disabled={isStoreNameSet && storeNameChangeCount === 1}
                >
                  {isStoreNameSet ? "Change store name" : "Set Store Name"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Body>
              <h4>Your eCommerce/Store Link</h4>
              <p>
                <a
                  href="https://your-ecommerce-link.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://your-ecommerce-link.com
                </a>
              </p>

              <h4>Current Plan: {packageTitle}</h4>

              <Button
                variant="primary"
                className="w-100 text-uppercase"
                onClick={() => handleUpgradePlan("Premium")}
                disabled={selectedPlan === "Premium"}
              >
                Upgrade Package
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showAlert && (
        <Row className="mb-4">
          <Col>
            <Alert variant="success">
              Store name has been successfully set!
            </Alert>
          </Col>
        </Row>
      )}

      {packageTitle !== "Flash" && packageTitle !== "Spark" && (
        <Row className="mb-4">
          <Col md={6}>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <h4 className="w-100 text-uppercase text-center">
                  Add Category buttons
                </h4>
                <small className="d-flex w-100 justify-content-center align-items-center text-center ">
                  The buttons will appear in the store
                </small>

                <Form onSubmit={handleAddCategory} className="mt-3">
                  <Form.Group className="mb-3">
                    <Form.Label className="">Category Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter category name"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="success"
                    className="w-100 rounded text-uppercase"
                  >
                    Add Category
                  </Button>
                </Form>
                {categories.length > 0 && (
                  <div className="mt-4">
                    <h5>Categories:</h5>
                    {categories.map((category, index) => (
                      <Button
                        key={index}
                        variant="outline-secondary"
                        className="me-2 mb-2"
                        onClick={() => handleRemoveCategory(index)}
                      >
                        {category} <span aria-hidden="true">&times;</span>
                      </Button>
                    ))}
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Modal show={showEditModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">
            Update Your Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdateUserInfo}>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Your name"
                value={userInfo.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Your email address"
                value={userInfo.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Your phone number"
                value={userInfo.number}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button
              type="submit"
              variant="secondary"
              className="w-100 rounded text-uppercase"
            >
              Update Info
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Settings;
