import React from "react";

function BusinessGrowth() {
  return (
    
    <section className="py-5">
      <div className="container">
        <h2 className="text-center mb-5">Grow Your Business with E-commerce</h2>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card h-100" role="button">
              <div className="card-body">
                <h5 className="card-title">Increased Reach</h5>
                <p className="card-text">Reach customers nationally, breaking geographical barriers.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4" role="button">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">24/7 Availability</h5>
                <p className="card-text">Your online store operates round the clock, catering to customers anytime.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4" role="button">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">Cost Savings</h5>
                <p className="card-text">Reduce overhead costs associated with brick-and-mortar stores.</p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}

export default BusinessGrowth;
