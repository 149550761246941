import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { ref, onValue, update } from "firebase/database";
import { database } from "../firebaseConfig";
import { FaUser } from "react-icons/fa";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const NewUserSettings = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    number: "",
    storeName: "",
    domain: "",
  });
  const [step, setStep] = useState(1);
  const [isStoreNameSet, setIsStoreNameSet] = useState(false);
  const [storeNameChangeCount, setStoreNameChangeCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [categoryCount, setCategoryCount] = useState(0);
  const [domainAvailability, setDomainAvailability] = useState(null);
  const [domainCheckInProgress, setDomainCheckInProgress] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);

      // Fetch user data from Firebase on component mount
      const fetchUserData = async () => {
        try {
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
              setUserInfo(userData);
              setIsStoreNameSet(!!userData.storeName);
              setStoreNameChangeCount(userData.storeNameChangeCount || 0);
              setCategories(userData.categories || []);

              // Check if selectedPackage exists and set the package title
              if (
                userData.selectedPackage &&
                userData.selectedPackage.package
              ) {
                setPackageTitle(userData.selectedPackage.package.title);
              }
            }
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  useEffect(() => {
    // Count the number of categories
    setCategoryCount(categories.length);
  }, [categories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  function handleSetStoreName(e) {
    e.preventDefault();
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      if (!isStoreNameSet) {
        setIsStoreNameSet(true);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
        update(userRef, {
          storeName: userInfo.storeName,
          storeNameChangeCount: 1,
        })
          .then(() => {
            console.log("Store name updated successfully:", userInfo.storeName);

            setStep(2); // Move to the next step
          })
          .catch((error) => {
            console.error("Error updating store name:", error);
          });
      } else if (storeNameChangeCount === 1) {
        // Charge R500 for subsequent changes
        // Implement payment logic here
        console.log("Charge R500 for store name change.");
      }
    }
  }

  const Continue = (newPlan) => {
    navigate("/StoreLayout");
    // Update plan i`   nformation in Firebase if needed
  };

  const handleCheckDomain = async (e) => {
    e.preventDefault();
    setDomainCheckInProgress(true);
    try {
      const response = await axios.get(
        `https://domain-checker-api-git-main-givens-projects-48cf6823.vercel.app/check-domain?domain=${userInfo.domain}`
      );
      setDomainAvailability(response.data.available);
      setDomainCheckInProgress(false);
    } catch (error) {
      console.error("Error checking domain availability:", error);
      setDomainCheckInProgress(false);
    }
  };

  const handleAcceptDomain = () => {
    if (currentUser && domainAvailability) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);
      update(userRef, { domain: userInfo.domain })
        .then(() => {
          console.log("Domain updated successfully:", userInfo.domain);
          navigate("/StoreLayout");
          setDomainAvailability(null); // Reset domain availability
        })
        .catch((error) => {
          console.error("Error updating domain:", error);
        });
    }
  };

  const handleSkip = () => {
    navigate("/StoreLayout");
  };

  return (
    <Container className="mt-3">
      <Row className="mb-4">
        <Col md={6}>
          <Card.Body>
            <div className="text-center">
              <img
                className="img-fluid"
                src={require("../assets/Ecom-Icon.png")}
                alt="Ecom Icon"
              />
            </div>
            <h4 className="w-100 text-center text-uppercase fade-in-text">
              <b>Welcome to EcomElevate</b>
            </h4>
            <p className="fade-in-text">
              <br />
              Our advanced platform is designed to efficiently set up your
              store, empowering you to launch your business swiftly and
              seamlessly.
            </p>

            {step === 1 && (
              <Form onSubmit={handleSetStoreName} className="mt-4">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="storeName"
                    value={userInfo.storeName}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Button type="submit" className="w-100 text-center">
                  Continue
                </Button>
              </Form>
            )}

            {step === 2 && (
              <Form onSubmit={handleCheckDomain} className="mt-4">
                <Form.Group controlId="formDomain" className="mb-3">
                  <Form.Label className="fw-bold">URL Link/Domain</Form.Label>
                  <Form.Control
                    type="text"
                    name="domain"
                    placeholder="e.g www.storename.co.za/com/org"
                    value={userInfo.domain}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="success"
                  className="w-100 round"
                  disabled={domainCheckInProgress}
                >
                  {domainCheckInProgress ? "Checking..." : "Check Domain"}
                </Button>
              </Form>
            )}

            {domainAvailability !== null && (
              <div className="mt-3">
                {domainAvailability ? (
                  <>
                    <Alert variant="success">Domain is available!</Alert>
                    {/* <Button variant="secondary" onClick={handleAcceptDomain}>
                      Accept Domain
                    </Button> */}
                    <Button
                      onClick={handleAcceptDomain}
                      className="w-100 text-center"
                    >
                      Accept Domain & Continue
                    </Button>
                  </>
                ) : (
                  <Alert variant="danger">
                    Domain/URL Link is not available.
                  </Alert>
                )}
              </div>
            )}

            <Button
              onClick={handleSkip}
              variant="secondary"
              className="w-100 text-center mt-5"
            >
              Skip For now
            </Button>
          </Card.Body>
        </Col>
      </Row>

      {showAlert && (
        <Row className="mb-4">
          <Col>
            <Alert variant="success">
              Store name has been successfully set!
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default NewUserSettings;
