import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark text-white py-4">
      <div className="container text-center">
        <div className="row mb-3">
          <div className="col-md-6 mb-3 mb-md-0">
            <h5>Follow Us</h5>
            <div className="d-flex justify-content-center">
              <a href="https://facebook.com" className="text-white mx-3">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a href="https://twitter.com" className="text-white mx-3">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a href="https://instagram.com" className="text-white mx-3">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://linkedin.com" className="text-white mx-3">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <p>
              At EcomElevate, we're on a mission to accelerate the adoption of
              ecommerce solutions, moving us towards a future of greater digital
              commerce, faster.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="mb-0">
              &copy; <span style={{color: "#5ab1a0"}}>{currentYear}</span> EcomElevate. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
