import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { getDatabase, ref, get, orderByChild } from "firebase/database"; // Import necessary functions from Firebase Realtime Database
import Nav from "../Nav";

class News extends Component {
  state = {
    news: [],
    currentNews: null,
    loading: true,
    error: null,
  };

  async componentDidMount() {
    try {
      const db = getDatabase();
      const newsRef = ref(db, "news");

      const snapshot = await get(newsRef);
      if (snapshot.exists()) {
        const newsArray = [];
        snapshot.forEach((childSnapshot) => {
          const newsItem = {
            id: childSnapshot.key,
            ...childSnapshot.val(),
          };
          newsArray.push(newsItem);
        });

        newsArray.sort((a, b) => new Date(b.date) - new Date(a.date));

        this.setState({
          news: newsArray,
          currentNews: newsArray.length > 0 ? newsArray[0] : null,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
          error: "No news data found.",
        });
      }
    } catch (error) {
      console.error("Error fetching news data: ", error);
      this.setState({
        loading: false,
        error: "Failed to load news data. Please try again later.",
      });
    }
  }

  render() {
    const { news, currentNews, loading, error } = this.state;

    return (
      <>
        <Nav />
        <div className="container mt-4">
          <Helmet>
            <title>EcomElevate News | Dropshipping & Ecommerce Solutions</title>
            <meta
              name="description"
              content="EcomElvate News - Latest updates on dropshipping stores, ecommerce solutions, and online selling strategies. Stay informed with our expert insights."
            />
            <meta
              name="keywords"
              content="EcomElvate, dropshipping, ecommerce store, online selling, news, updates"
            />
            <link rel="canonical" href="https://www.ecomlevate.com/news" />
          </Helmet>
          <h1 className="text-center mb-4">News</h1>
          <p className="text-center">
            Stay updated with the latest from EcomElvate – your partner in
            building successful dropshipping stores and ecommerce solutions.
          </p>

          {loading ? (
            <p className="text-center">Loading news...</p>
          ) : error ? (
            <p className="text-center text-danger">{error}</p>
          ) : (
            <div className="row">
              {/* Main News Section */}
              <div className="col-lg-8 col-md-12 mb-4">
                {currentNews && (
                  <div className="card">
                    <img
                      src={currentNews.imageUrl}
                      className="card-img-top"
                      alt={currentNews.title}
                    />
                    <div className="card-body">
                      {/* <h2 className="card-title">{currentNews.title}</h2> */}
                      <p className="card-text" dangerouslySetInnerHTML={{ __html: currentNews.description }} />
                    </div>
                  </div>
                )}
              </div>

              {/* Older News Tiles */}
              <div className="col-lg-4 col-md-12">
                <h3>Other News</h3>
                {news.slice(1).map((article) => (
                  <div className="card mb-3" key={article.id}>
                    <img
                      src={article.imageUrl}
                      className="card-img-top"
                      alt={article.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{article.title}</h5>
                      
                    
                        <p className="card-text" dangerouslySetInnerHTML={{ __html:article.description.substring(0, 100)}} />  
        
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default News;
