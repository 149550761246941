import React, { useState, useEffect, useContext } from "react";
import ProductTable from "../Components/TableComp";
import UploadComp from "../Components/UploadComp";
import "../App.css";
import Statistics from "../Components/SalesStatistics/statistics";
import Orders from "../Components/ParceInfo/Orders";
import {
  FaChartLine,
  FaCloudUploadAlt,
  FaHeadset,
  FaMicrophone,
  FaStore,
  FaUpload,
} from "react-icons/fa";
import { FaFirstOrderAlt, FaGear, FaListCheck } from "react-icons/fa6";
import Settings from "../Components/StoreAdmin/StoreSettings";
import { AuthContext } from "../AuthContext";
import { ref, onValue } from "firebase/database";
import { database } from "../firebaseConfig";
import SupportPage from "../Components/Support/support";

function Home() {
  const { currentUser } = useContext(AuthContext);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [DemoActive, setDemoActive] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("itemsListed");

  // Function to check screen size on mount and resize
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };

  const closeSideMenu = () => {
    setShowSideMenu(false);
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    closeSideMenu();
  };

  // Effect to check screen size on mount and resize
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Dynamic heading based on selected menu
  const getMenuTitle = () => {
    switch (selectedMenu) {
      case "itemsListed":
        return "Store Products";
      case "saleStatistics":
        return "STORE OVERVIEW";
      case "storeManagement":
        return "Add/Upload Product";
      case "storeSettings":
        return "Store Settings";
      case "Orders":
        return "Customer Orders";
      default:
        return "STORE ADMIN";
    }
  };

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}/selectedPackage`);

      // Fetch user data from Firebase on component mount
      const fetchUserData = async () => {
        try {
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
              // Check if selectedPackage exists and set the package title

              setDemoActive(userData.isDemo);
            }
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm border-bottom">
        <a className="navbar-brand fw-bold pl-4" href="#">
          {!isMobile && (
            <img src={require("../assets/Ecom-Icon.png")} alt="Logo" />
          )}
          <strong>EcomElevate</strong>
        </a>
        {isMobile && (
          <button className="btn btn-secondary" onClick={toggleSideMenu}>
            ☰
          </button>
        )}
      </nav>

      <div className="container-fluid">
        <div className="row">
          {!isMobile && (
            <nav
              className={`col-md-3 col-lg-2 d-md-block bg-light sidebar  ${
                showSideMenu ? "show" : ""
              } shadow`}
            >
              <div className="sidebar-sticky mt-3">
                <ul className="nav flex-column fw-bold">
                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "itemsListed" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("itemsListed")}
                    >
                      <FaStore size={24} /> Store Products
                    </p>
                  </li>
                  {!DemoActive && (
                    <li className="nav-item">
                      <p
                        className={` ${
                          selectedMenu === "saleStatistics" ? "active" : ""
                        } text-dark lead`}
                        role="button"
                        onClick={() => handleMenuClick("saleStatistics")}
                      >
                        <FaChartLine size={24} /> Statistics
                      </p>
                    </li>
                  )}
                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "storeManagement" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("storeManagement")}
                    >
                      <FaCloudUploadAlt size={24} /> Upload Product
                    </p>
                  </li>
                  {!DemoActive && (
                    <li className="nav-item">
                      <p
                        className={` ${
                          selectedMenu === "Orders" ? "active" : ""
                        } text-dark lead`}
                        role="button"
                        onClick={() => handleMenuClick("Orders")}
                      >
                        <FaListCheck size={24} /> Orders
                      </p>
                    </li>
                  )}
                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "storeSettings" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("storeSettings")}
                    >
                      <FaGear size={24} /> Store Settings
                    </p>
                  </li>

                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "support" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("support")}
                    >
                      <FaHeadset size={24} /> Support
                    </p>
                  </li>
                </ul>
              </div>
            </nav>
          )}

          <main
            role="main"
            className={`${
              isMobile ? "col-12" : "col-md-9 ml-sm-auto col-lg-10 px-4"
            } ${isMobile ? "mobile-view" : ""}`}
          >
            {isMobile && showSideMenu && (
              <div className="mobile-sidebar bg-light p-3">
                <ul className="nav flex-column fw-bold">
                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "itemsListed" ? "active" : ""
                      } text-dark lead`}
                      href="#"
                      onClick={() => handleMenuClick("itemsListed")}
                    >
                      <FaStore size={24} /> Store Products  
                    </p>
                  </li>
                  {!DemoActive && (
                    <li className="nav-item">
                      <p
                        className={` ${
                          selectedMenu === "saleStatistics" ? "active" : ""
                        } text-dark lead`}
                        role="button"
                        onClick={() => handleMenuClick("saleStatistics")}
                      >
                        <FaChartLine size={24} /> Statistics
                      </p>
                    </li>
                  )}
                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "storeManagement" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("storeManagement")}
                    >
                      <FaCloudUploadAlt size={24} /> Add/upload Product
                    </p>
                  </li>
                  {!DemoActive && (
                    <li className="nav-item">
                      <p
                        className={`${
                          selectedMenu === "Orders" ? "active" : ""
                        } text-dark lead`}
                        role="button"
                        onClick={() => handleMenuClick("Orders")}
                      >
                        <FaListCheck size={24} /> Orders
                      </p>
                    </li>
                  )}

                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "storeSettings" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("storeSettings")}
                    >
                      <FaGear size={24} /> Store Settings
                    </p>
                  </li>

                  <li className="nav-item">
                    <p
                      className={` ${
                        selectedMenu === "support" ? "active" : ""
                      } text-dark lead`}
                      role="button"
                      onClick={() => handleMenuClick("support")}
                    >
                      <FaHeadset size={24} /> Support
                    </p>
                  </li>
                </ul>
              </div>
            )}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
              <h1 className="h2  text-uppercase">{getMenuTitle()}</h1> {DemoActive && <span className="badge bg-warning text-dark text-uppercase">Trial mode</span>}
            </div>

            <hr />
            <div className="scrollable-content">
              {selectedMenu === "itemsListed" && <ProductTable />}
              {selectedMenu === "storeManagement" && <UploadComp />}
              {selectedMenu === "saleStatistics" && !DemoActive && (
                <Statistics />
              )}
              {selectedMenu === "Orders" && !DemoActive && <Orders />}
              {selectedMenu === "storeSettings" && <Settings />}
              {selectedMenu === "support" && <SupportPage />}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Home;
