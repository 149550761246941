import React, { useState, useEffect,useContext} from "react";
import { ref, onValue, remove, update } from "firebase/database";
import { database } from "../firebaseConfig";
import { AuthContext } from "../AuthContext";

function ProductTable() {
  const [products, setProducts] = useState([]);
  const [editId, setEditId] = useState(null);
  const [formData, setFormData] = useState({});
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Fetch data from Firebase Realtime Database
    const fetchData = () => {
      const userId = currentUser.uid;
      const productsRef = ref(database, `users/${userId}/products`);
      onValue(productsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Include the product ID in the products array
          const productsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setProducts(productsArray);
        }
      });
    };

    fetchData();
  }, []);

  // Function to delete a product by ID
  const deleteProduct = (id) => {
    const userId = currentUser.uid;
    remove(ref(database, `users/${userId}/products/${id}`));
    setProducts(products.filter((product) => product.id !== id));
  };

  // Function to update a product
  const updateProduct = () => {
    const userId = currentUser.uid;
    const { id, ...productData } = formData;
    update(ref(database, `users/${userId}/products/${id}`), productData);
    setEditId(null);
    setFormData({});
  };

  // Function to toggle edit mode for a product
  const toggleEdit = (product) => {
    setEditId(product.id);
    setFormData(product);
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="container responsive">

      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th className="">Image</th>
              <th className="text-nowrap">Product Name</th>
              <th>Price</th>
              <th className="text-nowrap">Cancel Price</th>
              <th className="text-nowrap">In stock</th>
              <th>Category</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td className="align-middle">
                  <img
                    className="product-img"
                    src={product.img}
                    alt="Product"
                  />
                </td>
                <td className="text-nowrap">
                  {editId === product.id ? (
                    <input
                      type="text"
                      name="ProductName"
                      value={formData.ProductName || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    product.ProductName
                  )}
                </td>
                <td className="text-nowrap">
                  {editId === product.id ? (
                    <input
                      type="text"
                      name="Price"
                      value={formData.Price || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    product.Price
                  )}
                </td>

                <td className="text-nowrap">
                  {editId === product.id ? (
                    <input
                      type="text"
                      name="CancalledPrice"
                      value={formData.CancalledPrice || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    product.CancalledPrice
                  )}
                </td>
                <td className="text-nowrap">
                  {editId === product.id ? (
                    <input
                      type="text"
                      name="Instock"
                      value={formData.Instock || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    product.Instock
                  )}
                </td>
                <td className="text-nowrap">
                  {editId === product.id ? (
                    <input
                      type="text"
                      name="category"
                      value={formData.category || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    product.category
                  )}
                </td>
                <td>
                  {editId === product.id ? (
                    <input
                      type="text"
                      name="Description"
                      className="w-100"
                      value={formData.Description || ""}
                      onChange={handleChange}
                    />
                  ) : (
                    product.Description
                  )}
                </td>
                <td>
                  {editId === product.id ? (
                    <button className="btn btn-success" onClick={updateProduct}>
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary mb-2"
                      onClick={() => toggleEdit(product)}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProduct(product.id)}
                    disabled={editId === product.id}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProductTable;
