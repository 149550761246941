import React, { useState, useContext, useEffect } from "react";
import { push, ref, onValue, update } from "firebase/database";
import { database, storage } from "../firebaseConfig";
import {
  uploadBytes,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
import { AuthContext } from "../AuthContext";

function UploadComp() {
  const { currentUser } = useContext(AuthContext);
  const [packageTitle, setPackageTitle] = useState("");
  const [uploadedCount, setUploadedCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    img: null,
    additionalImages: [],
    Price: "",
    CancalledPrice: "",
    ProductName: "",
    category: "",
    Description: "",
    Instock: "in stock",
    ProductInformation: [],
    AboutProduct: [],
  });

  // console.log("the package", packageTitle);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      img: file,
    }));
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevState) => ({
      ...prevState,
      additionalImages: files,
    }));
  };

  const handleProductInfoChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedProductInformation = [...prevState.ProductInformation];
      updatedProductInformation[index] = {
        ...updatedProductInformation[index],
        [name]: value,
      };
      return {
        ...prevState,
        ProductInformation: updatedProductInformation,
      };
    });
  };

  const handleAddProductInfo = () => {
    setFormData((prevState) => ({
      ...prevState,
      ProductInformation: [
        ...prevState.ProductInformation,
        { productName: "", quantity: "" },
      ],
    }));
  };

  const handleRemoveProductInfo = (index) => {
    setFormData((prevState) => {
      const updatedProductInformation = [...prevState.ProductInformation];
      updatedProductInformation.splice(index, 1);
      return {
        ...prevState,
        ProductInformation: updatedProductInformation,
      };
    });
  };

  const handleAboutProductChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedAboutProduct = [...prevState.AboutProduct];
      updatedAboutProduct[index] = {
        ...updatedAboutProduct[index],
        [name]: value,
      };
      return {
        ...prevState,
        AboutProduct: updatedAboutProduct,
      };
    });
  };

  const handleAddAboutProduct = () => {
    setFormData((prevState) => ({
      ...prevState,
      AboutProduct: [...prevState.AboutProduct, { info: "" }],
    }));
  };

  const handleRemoveAboutProduct = (index) => {
    setFormData((prevState) => {
      const updatedAboutProduct = [...prevState.AboutProduct];
      updatedAboutProduct.splice(index, 1);
      return {
        ...prevState,
        AboutProduct: updatedAboutProduct,
      };
    });
  };

  const updateUploadedCountInFirebase = async (userId, newCount) => {
    const userRef = ref(database, `users/${userId}`);
    try {
      await update(userRef, { uploadedCount: newCount });
    } catch (error) {
      console.error("Error updating uploaded count in Firebase:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = currentUser.uid;
    setLoading(true);
    // Check package limits
    const limits = {
      Flash: 30,
      Spark: 60,
    };
    const maxUploads = limits[packageTitle] || Infinity;

    if (uploadedCount >= maxUploads) {
      alert(`Your package allows only ${maxUploads} items to be uploaded.`);
      return;
    }

    try {
      // Upload main image to Firebase Storage
      const imgStorageRef = storageRef(
        storage,
        `MainImages/${formData.img.name}`
      );
      await uploadBytes(imgStorageRef, formData.img);
      const imgUrl = await getDownloadURL(imgStorageRef);
      console.log("Main image uploaded:", imgUrl);

      // Upload additional images to Firebase Storage and get their URLs
      const additionalImagesUrls = await Promise.all(
        formData.additionalImages.map(async (file, index) => {
          const additionalImgStorageRef = storageRef(
            storage,
            `AdditionalImages/${Date.now()}_${file.name}`
          );
          await uploadBytes(additionalImgStorageRef, file);
          const url = await getDownloadURL(additionalImgStorageRef);
          console.log(`Additional image ${index + 1} uploaded:`, url);
          return url;
        })
      );

      // Store the image URLs along with other form data in Firebase Realtime Database
      const productsRef = ref(database, `users/${userId}/products`);
      await push(productsRef, {
        ...formData,
        img: imgUrl,
        additionalImages: additionalImagesUrls,
      });

      setLoading(false);
      // Clear form data after submission
      setFormData({
        img: "",
        additionalImages: [],
        Price: "",
        CancalledPrice: "",
        ProductName: "",
        category: "",
        Description: "",
        Instock: "in stock",
        ProductInformation: [],
        AboutProduct: [],
      });

      // Update uploaded count
      const newUploadedCount = uploadedCount + 1;
      setUploadedCount(newUploadedCount);
      updateUploadedCountInFirebase(userId, newUploadedCount);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const userRef = ref(database, `users/${userId}`);

      // Fetch user data from Firebase on component mount
      const fetchUserData = async () => {
        try {
          onValue(userRef, (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
              // Check if selectedPackage exists and set the package title
              if (
                userData.selectedPackage &&
                userData.selectedPackage.package
              ) {
                setPackageTitle(userData.selectedPackage.package.title);
              }

              // Fetch uploaded count
              if (userData.uploadedCount !== undefined) {
                setUploadedCount(userData.uploadedCount);
              } else {
                setUploadedCount(0); // Default to 0 if not found
              }
            }
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  return (
    <div className="container p-5">
      <div className="row justify-content-center">
        <div className="col-12 text-start">
          <h2 className="mt-0 lead">ADD A NEW ITEM TO THE STORE</h2>
        </div>
      </div>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <label className="font-weight-bold">Product Name</label>
              <input
                type="text"
                className="form-control"
                name="ProductName"
                placeholder="flowers"
                value={formData.ProductName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <label className="font-weight-bold">Main product Image</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              name="img"
              onChange={handleImageChange}
              required
            />
            <div className="additional-images-preview">
              {formData.img && (
                <img
                  className="product-img-thumbnail"
                  src={URL.createObjectURL(formData.img)}
                  alt="Main product"
                  style={{
                    marginTop: "10px",
                    maxWidth: "50px",
                    maxHeight: "50px",
                  }}
                />
              )}
            </div>
          </div>
          {packageTitle !== "Spark" && packageTitle !== "Flash Plus" && (
            <div className="col-md-6">
              <label className="font-weight-bold">Additional Images</label>
              <input
                type="file"
                className="form-control"
                name="additionalImages"
                onChange={handleAdditionalImagesChange}
                multiple
                accept="image/*"
              />
              <div className="additional-images-preview">
                {formData.additionalImages.map((file, index) => (
                  <img
                    className="product-img-thumbnail"
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`Additional ${index + 1}`}
                    style={{
                      marginTop: "10px",
                      maxWidth: "50px",
                      maxHeight: "50px",
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="col-md-6">
            <label className="font-weight-bold">Product Price</label>
            <input
              type="number"
              className="form-control"
              name="Price"
              placeholder="$"
              value={formData.Price}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="font-weight-bold">Discounted Price</label>
            <input
              type="number"
              className="form-control"
              name="CancalledPrice"
              placeholder="$"
              value={formData.CancalledPrice}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label className="font-weight-bold">Description</label>
            <textarea
              className="form-control"
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="font-weight-bold">Product Category</label>
            <input
              type="text"
              className="form-control"
              name="category"
              placeholder="decor"
              value={formData.category}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="font-weight-bold">Product Stock</label>
            <select
              className="form-control"
              name="Instock"
              value={formData.Instock}
              onChange={handleChange}
            >
              <option value="in stock">In Stock</option>
              <option value="out of stock">Out of Stock</option>
            </select>
          </div>

          <div className="col-md-6">
            <label className="font-weight-bold">Product Information</label>
            {formData.ProductInformation.map((info, index) => (
              <div key={index} className="row mb-2">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="productName"
                    placeholder="e.g Laptop"
                    value={info.productName}
                    onChange={(e) => handleProductInfoChange(index, e)}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="quantity"
                    placeholder="e.g What in the box 1x charger 1xlaptop"
                    value={info.quantity}
                    onChange={(e) => handleProductInfoChange(index, e)}
                  />
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveProductInfo(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <br />
            <button
              type="button"
              className="btn btn-secondary mt-2"
              onClick={handleAddProductInfo}
            >
              Add Product Info
            </button>
          </div>

          <div className="col-md-6">
            <label className="font-weight-bold">About Product</label>
            {formData.AboutProduct.map((about, index) => (
              <div key={index} className="row mb-2">
                <div className="col">
                  <textarea
                    className="form-control"
                    name="info"
                    placeholder="e.g Hassle Free Exchange & Returns"
                    value={about.info}
                    onChange={(e) => handleAboutProductChange(index, e)}
                  />
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveAboutProduct(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <br />
            <button
              type="button"
              className="btn btn-secondary mt-2"
              onClick={handleAddAboutProduct}
            >
              Add About Product
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-end">
            <button
              type="submit"
              className="btn btn-success w-100 text-center text-uppercase"
              disabled={loading}
            >
              {loading ? (
                <>
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                "Upload Product"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UploadComp;
