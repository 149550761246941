import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";

const SupportPage = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="text-center shadow">
            <Card.Body>
              <Card.Title className="mb-4 text-uppercase">Support</Card.Title>
              <Card.Text>
                For any assistance, please reach out to us through the following
                channels:
              </Card.Text>
              <Card.Text className="mb-4">
                <FaEnvelope className="me-2" />
                <a
                  href="mailto:support@ecomlevate.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  support@ecomlevate.com
                </a>
              </Card.Text>
              <Button
                href="https://wa.me/+27816155344"
                variant="success"
                className="d-flex align-items-center justify-content-center w-100"
                style={{ backgroundColor: "#25D366", borderColor: "#25D366" }}
              >
                <FaWhatsapp className="me-2" /> Contact Us on WhatsApp
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SupportPage;
